var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"card mb-3 profile-card",staticStyle:{"width":"100%"},attrs:{"element-loading-text":"Loading...","element-loading-spinner":"el-icon-loading","element-loading-background":"rgba(0, 0, 0, 0.8)"}},[_vm._m(0),_c('el-form',{ref:"form",attrs:{"model":_vm.updatePersonalInformation}},[_c('el-row',[_c('el-col',{attrs:{"xl":8,"lg":8,"md":8,"sm":12,"xs":12}},[_c('el-form-item',{staticClass:"mr-1 ml-1 requiredLables",attrs:{"label":"First Name"}},[_c('el-input',{model:{value:(_vm.updatePersonalInformation.first_name),callback:function ($$v) {_vm.$set(_vm.updatePersonalInformation, "first_name", $$v)},expression:"updatePersonalInformation.first_name"}}),(
                _vm.getUpdateProfileErrors && _vm.getUpdateProfileErrors.first_name
              )?_c('p',{staticClass:"error"},[_vm._v(" "+_vm._s(_vm.getUpdateProfileErrors.first_name)+" ")]):_vm._e()],1)],1),_c('el-col',{attrs:{"xl":8,"lg":8,"md":8,"sm":12,"xs":12}},[_c('el-form-item',{staticClass:"mr-1 ml-1 requiredLables",attrs:{"label":"Last Name"}},[_c('el-input',{model:{value:(_vm.updatePersonalInformation.last_name),callback:function ($$v) {_vm.$set(_vm.updatePersonalInformation, "last_name", $$v)},expression:"updatePersonalInformation.last_name"}}),(
                _vm.getUpdateProfileErrors && _vm.getUpdateProfileErrors.last_name
              )?_c('p',{staticClass:"error"},[_vm._v(" "+_vm._s(_vm.getUpdateProfileErrors.last_name)+" ")]):_vm._e()],1)],1),_c('el-col',{attrs:{"xl":8,"lg":8,"md":8,"sm":12,"xs":12}},[_c('el-form-item',{staticClass:"mr-1 ml-1",attrs:{"label":"Title"}},[_c('el-input',{model:{value:(_vm.updatePersonalInformation.title),callback:function ($$v) {_vm.$set(_vm.updatePersonalInformation, "title", $$v)},expression:"updatePersonalInformation.title"}}),(_vm.getUpdateProfileErrors && _vm.getUpdateProfileErrors.title)?_c('p',{staticClass:"error"},[_vm._v(" "+_vm._s(_vm.getUpdateProfileErrors.title)+" ")]):_vm._e()],1)],1),_c('el-col',{attrs:{"xl":8,"lg":8,"md":8,"sm":12,"xs":12}},[_c('el-form-item',{staticClass:"mr-1 ml-1 requiredLable",attrs:{"label":"Phone"}},[_c('el-input',{model:{value:(_vm.updatePersonalInformation.phone),callback:function ($$v) {_vm.$set(_vm.updatePersonalInformation, "phone", $$v)},expression:"updatePersonalInformation.phone"}}),(_vm.getUpdateProfileErrors && _vm.getUpdateProfileErrors.phone)?_c('p',{staticClass:"error"},[_vm._v(" "+_vm._s(_vm.getUpdateProfileErrors.phone)+" ")]):_vm._e()],1)],1),_c('el-col',{attrs:{"xl":8,"lg":8,"md":8,"sm":12,"xs":12}},[_c('el-form-item',{staticClass:"ml-1 mr-1",attrs:{"label":"Address"}},[[_c('el-input',{model:{value:(_vm.updatePersonalInformation.address),callback:function ($$v) {_vm.$set(_vm.updatePersonalInformation, "address", $$v)},expression:"updatePersonalInformation.address"}})],(_vm.getUpdateProfileErrors && _vm.getUpdateProfileErrors.address)?_c('p',{staticClass:"error"},[_vm._v(" "+_vm._s(_vm.getUpdateProfileErrors.address)+" ")]):_vm._e()],2)],1),(
            _vm.getActiveWorkspace.company_id &&
            _vm.getActiveWorkspace.company_id != '0'
          )?_c('el-col',{attrs:{"xl":8,"lg":8,"md":8,"sm":12,"xs":12}},[_c('el-form-item',{staticClass:"ml-1 mr-1",attrs:{"label":"Email Address"}},[_c('el-input',{attrs:{"disabled":""},model:{value:(_vm.getAuthenticatedUser.email),callback:function ($$v) {_vm.$set(_vm.getAuthenticatedUser, "email", $$v)},expression:"getAuthenticatedUser.email"}},[_c('p',[_vm._v(_vm._s(this.getAuthenticatedUser.email))])])],1)],1):_vm._e()],1),(_vm.getUpdateProfileErrors && _vm.getUpdateProfileErrors.critical_error)?_c('p',{staticClass:"error"},[_vm._v(" "+_vm._s(_vm.getUpdateProfileErrors.critical_error)+" ")]):_vm._e(),_c('div',{staticClass:"card-footer bg-white"},[_c('el-form-item',{staticClass:"mb-0 p-2"},[_c('el-button',{on:{"click":_vm.getOldData}},[_vm._v("Cancel")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.checkProfileData}},[_vm._v("Save Changes")])],1)],1)],1)],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header bg-light"},[_c('h3',{staticClass:"title fw-normal mb-0"},[_vm._v("Personal Information")])])
}]

export { render, staticRenderFns }